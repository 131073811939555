import React from 'react'
import { Contact, Map, Page } from '@components'

const metaData = {
  title: 'contact.seo.title',
  description: 'contact.seo.description'
}

const P = props => {
  const { locale } = props.pageContext
  return (
    <Page locale={locale} meta={metaData}>
      <Contact />
      <Map />
    </Page>
  )
}

export default P
